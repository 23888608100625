import logo from "./logo2.png";
import "./App.css";

import React, { useState, useRef } from "react";
import imageCompression from "browser-image-compression";
import { BrowserView, MobileView, isMobile } from 'react-device-detect';

function toMB(num) {
  return (num / 1024 / 1024).toFixed(2);
}

function App() {
  const [uploaded, setUploaded] = useState(0);
  const [originalImage, setOriginalImage] = useState("");
  const [compressedImage, setCompressedImage] = useState("");
  const fileInputRef = useRef(null);

  // File Upload
  const handleFileUpload = (e) => {
    setOriginalImage(e.target.files[0]);
    setUploaded(1);

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    imageCompression(e.target.files[0], options).then((img) => {
      setCompressedImage(img);
    });
  };

  const handleButtonClick = (e) => {
    fileInputRef.current.click();
  };

  return (
    <div>

      <table>
        <tr>
          <BrowserView><th style={{width:"150px"}}></th></BrowserView>
          <MobileView><th></th></MobileView>
          <th>
            <img src={process.env.PUBLIC_URL + "/logo192.png"} alt="logo" />
            <h1>Reducir Imagen <small><br/>Reduce el tamaño de tu imagen instantáneamente</small></h1>
          </th>
          <BrowserView><th style={{width:"150px"}}></th></BrowserView>
          <MobileView><th></th></MobileView>
        </tr>
        <tr>
          <td id="add1"></td>

          <td>
            <div id="border" width="100%">
            En nuestra plataforma puedes cargar tus imágenes, y automáticamente las comprimimos para optimizar el espacio. Esto te permite utilizarlas sin inconvenientes en formularios de visados, pasaportes, correos electrónicos y en cualquier otro contexto que requiera imágenes de menor tamaño.
            <br/>¡Optimiza tus imágenes de manera sencilla, rápida y gratuita! 🌟📸
              <div><br/></div>
              <div className="container">
                <div class="center">
                  <button id="button" onClick={handleButtonClick}>
                    Subir Imagen
                  </button>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileUpload}
                  />
                </div>
              </div>
              <br />
              <div className="container">

                {compressedImage && (
                  <div id="compressedImg2">
                    <img
                      src={URL.createObjectURL(compressedImage)}
                      alt="Compressed Image"
                      id="compressedImg"
                      class="center"
                    />
                    <div><br/></div>
                    <a
                      href={URL.createObjectURL(compressedImage)}
                      download="output.jpg"
                      style={{"text-decoration": "none"}}
                    >
                      <div class="center">
                        <button id="button">Descargar Imagen</button>
                      </div>
                    </a>
                    <div><br/></div>
                    <div>
                    Tu imagen se redujo en un {" "}
                        {(
                          ((compressedImage.size - originalImage.size) * 100) /
                          originalImage.size
                        ).toFixed()}
                        %  🚀 ({toMB(originalImage.size)}MB → {toMB(compressedImage.size)}MB)
                    </div>
                  </div>
                )}
              </div>
              <hr/>
              <h3>Comprimir tu imagen es muy fácil</h3>
              ¿Quieres reducir el tamaño de una imagen jpg sin perder calidad y sin subirla a ningún servidor? Entonces, nuestra plataforma es la solución que necesitas.
Con nuestra plataforma, puedes seleccionar la imagen que quieras desde tu dispositivo y reducir su tamaño a menos de 1mb y con resolución full hd en cuestión de segundos. Todo el proceso se hace en tu propio dispositivo, por lo que no tienes que preocuparte por la privacidad ni el rendimiento de tu conexión. Además, nuestra plataforma es completamente gratuita y fácil de usar. Solo tienes que elegir la imagen, pulsar un botón y listo. Así de simple.
Prueba nuestra plataforma hoy mismo y comprueba la diferencia.
            </div>
          </td>
          <td id="add2"></td>
        </tr>
        <tr>
          <td></td>
          <td><div class="center"><a id="impressum" href={process.env.PUBLIC_URL + "/impressum.html"}>Impressum</a></div></td>
          <td ></td>
        </tr>
      </table>
    </div>
  );
}

/*
reducirimagen.com
recortarimagen.com
- reducirfoto.com

jpgverkleinern.com
reduzierbild.com
reduzierfoto.com

*/

export default App;
